import * as React from "react";
import * as PropTypes from "prop-types";
import {Card, CardContent, List} from "@material-ui/core";
import Header from "./Header";
import Pagination from "./Pagination";
import FilterBy from "./Header/Filters/FilterBy";
import FilterByDate from "./Header/Filters/FilterByDate";
import Refresh from "./Header/Filters/Refresh";
import Search from "./Header/Filters/Search";
import "./style.less";

class Table extends React.Component<any, any> {
    public static propTypes = {
        search: PropTypes.object,
        buttons: PropTypes.array,
        columns: PropTypes.array,
        total: PropTypes.any,
        rightFilters: PropTypes.array,
        leftFilters: PropTypes.array,
        export: PropTypes.array,
        expandAll: PropTypes.func,
        allExpanded: PropTypes.bool,
        style: PropTypes.object,
        pagination: PropTypes.shape({
            number: PropTypes.number.isRequired,
            size: PropTypes.number.isRequired,
            totalElements: PropTypes.number.isRequired,
            goToPage: PropTypes.func.isRequired,
            setPageSize: PropTypes.func.isRequired,
            loading: PropTypes.bool.isRequired
        })
    };

    public constructor(props) {
        super(props);

        this.state = {
            showMenu: false
        };
    }

    public render() {
        return <Card className="table-wrapper" style={this.props.style}>
            <Header {...this.props} showMenu={this.state.showMenu} menuAnchor={this.state.menuAnchor} allExpanded={this.props.allExpanded}
                toggleMenu={e => this.setState({showMenu: !this.state.showMenu, menuAnchor: e.currentTarget})} />
            <CardContent style={{padding: 0}}>
                <List style={{padding: 0}}>
                    {this.props.children}
                </List>
                {!!this.props.pagination && <Pagination {...this.props.pagination} />}
            </CardContent>
        </Card>;
    }
}

export default Table;
export {Table, FilterByDate, FilterBy, Refresh, Search}
